import { useState } from 'react';
import { useAuth } from '../../hooks/login/useAuth';
import './login.scss';
import axios from 'axios';
import { SERVER_HOST } from '../../shared/utils';
const Login = () => {
  const [userId, setUserId] = useState('');
  const [userPwd, setUserPwd] = useState('');
  const { login } = useAuth();

  /**
   * TODO config 설정 변경 필요
   * 
   * 로그인을 요청하는 메서드 
   * @value userId: 로그인 하는 유저 아이디
   * @value userPwd: 로그인 하는 유저 패스워드
   */
  const handleSubmit = () => {
    // console.log("userId: ", userId,", userPwd", userPwd );
    axios.post(SERVER_HOST + `/admin/auth`, {
      userid:userId,
      password:userPwd,
    })
    .then((res)=> {
      let data = res.data;
      if(data) {
        login({
          token: data
        })
      } else {
        /**
         * TODO 나중에 해당하는 메시지를 출력해 줄 필요 있음
         */
        alert("계정을 다시 확인해보세요");
      }
    })
    .catch((error) => {
      /**
       * TODO 나중에 해당하는 메시지를 출력해 줄 필요 있음
       */
      alert("에러 발생! 관리자한테 문의");
      console.error(error)
    });

  }

  return (
    <div className="login_wrapper">
      <div className='logo_wrapper'>
        <i className="login_logo"></i>
      </div>
      <div className="login_input">
        <input className="login_id" type="text" placeholder="ID" value={userId} onChange={(e)=>setUserId(e.target.value)}/>
        <input className="login_password" type="password" placeholder="PW" value={userPwd} onChange={(e)=> setUserPwd(e.target.value)}/>
      </div>
      <div className="login_button" onClick={handleSubmit}><p>로그인</p></div>
    </div>
  )
}
export default Login;