const WRITE_MODE_SETTING = 'write/WRITE_MODE_SETTING' as const;
const WRITE_UPDATE_DATA = 'write/WRITE_UPDATE_DATA' as const;

type WriteAction =
  | ReturnType<typeof writeModeSetting>
  | ReturnType<typeof writeUpdateData>
;

export const writeModeSetting = (diff: number) => ({
  type: WRITE_MODE_SETTING,
  payload: diff
});

export const writeUpdateData = (diff: any) => ({
  type: WRITE_UPDATE_DATA,
  payload: diff
});


export type WriteState = {
  mode: number;
  data?: any;
};

const initialState: WriteState = {
  mode: 1,
};

function write(
  state: WriteState = initialState,
  action: WriteAction
): WriteState {
  switch (action.type) {
    case WRITE_MODE_SETTING:
      return { ...state, mode: action.payload };
    case WRITE_UPDATE_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

export default write;