import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { BoardItem } from "../../components";
import classNames from 'classnames';
import './main.scss'
import { BoardItemProps, BoardProps } from "../../shared/types/board.types";

// import dummyData from "../../assets/dummy.json"; // 모든 데이터
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getData } from "../../shared/utils";
import { useDispatch } from 'react-redux';
import { writeModeSetting, writeUpdateData } from "../../store";
import { useTranslation } from "react-i18next";

const Main = () => {

  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [currentItems, setCurrentItems] = useState<Array<BoardItemProps>>([]); // 현재 페이지 아이템, 아이템을 5개씩 보여주기 위해서 필요
  const [currentOriginData, setCurrentOriginData] = useState<Array<BoardItemProps> | null>(); // 현재 페이지 모든 데이터
  const [pageCount, setPageCount] = useState(1); //현재 페이지 모든 데이터에 맞춰서 페이지 숫자
  const [nowTab, setNowTab] = useState(0); //현재 탭
  const currentPageCount = 10;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  /**
   * tab에 맞춰서 데이터를 저장 함
   * 
   * @author colen
   * @since 2022-12-14
   * @since 2022-12-22(Update)
   */
  useEffect(()=> {
      getData( nowTab === 0 ? 
        { lang: i18n.language}: 
        { category: TabData[nowTab].title,
          lang: i18n.language })
      .then(res => {
        // console.log("res", res);
        setCurrentOriginData(res);
      });
  },[i18n.language, nowTab]);

  const languageText = () => {
    if(i18n.language === 'ko') {
      return 'KR';
    } else if(i18n.language === 'en'){
      return 'EN';
    }
  }

  /**
   * 현재 페이지의 탭
   */
  const TabData = [
    { title: "Total" },
    { title: "News" },
    { title: "Notice" }
  ];

  /**
   * 페이지가 변경 되었는지 감지
   * 페이지 내의 데이터가 변경 되었는지 감지
   * 
   * setPageCount() 데이터 변경이 일어났을 때, 전체 페이지 수를 다시 셈
   * pageItemUpdate() 현재 페이지가 변경됬을 때, 현재 페이지의 데이터를 다시 불러 옴
   * 
   * @author jslee
   * @since 2022-12-14
   */
  useEffect(() => {
    if(currentOriginData) 
    {
      setPageCount(Math.ceil(currentOriginData.length / currentPageCount));
      pageItemUpdate();
    }
  },[currentPage, currentOriginData])


  useEffect(() => {
    // console.log("current Page Item", currentItems);
  },[currentItems])


  /**
   * 페이지가 변경됬을 때 현재 페이지를 변경
   * 
   * @param event 현재 페이지
   * 
   * @author jslee
   * @since 2022-12-14
   */
  const handlePageClick = (event : any) => {
    setCurrentPage(event.selected);
  };


  /**
   * 페이지나 페이지내 아이템의 변경이 일어났을 때 현재 페이지의 데이터를 다시 불러옴
   * 
   * @author jslee
   * @since 2022-12-14
   */
  const pageItemUpdate = async() => {
    // console.log("currentPage", currentPage);
    if(currentOriginData) {
      let data = currentOriginData.slice(currentPage * currentPageCount, (currentPage * currentPageCount) + currentPageCount);
      await setCurrentItems(data);
    }
  }

  /**
   * 탭을 클릭했을 때 데이터를 세팅 하는 부분
   * 
   * @param index 입력 받은 탭 number(전체: 0, News: 1, Notice: 2)
   * @author jslee
   * @since 2022-12-14
   */
  const setTabData = (index) => {
    setNowTab(index);
    setCurrentPage(0);
  }

  /**
   * 탭 랜더링 부분
   * 
   * @author jslee
   * @since 2022-12-14
   */
  const tabMap = TabData.map((data: any , index : number) => (
    <p 
      className={classNames({on : nowTab === index})}
      key={index}
      onClick={() => {
        setTabData(index);   
      }}
    >
      {data.title}
    </p>
  ));

  const movePage = async() => {
    dispatch(writeModeSetting(1));
    dispatch(writeUpdateData(null));
    await navigate(`/write`);
  }

  return (
    <div className="container board_manager">
      <div className="content_frame">
        <div className="board_title">
          <p>게시판 관리 ({languageText()})</p>
        </div>
        <div className="board_menu">
          {tabMap}
        </div>
      <table className="board_table">
        <thead>
          <tr>
            <th>구분</th>
            <th>제목</th>
            <th>작성일</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length !== 0 ? currentItems.map((e:any) => <BoardItem key={e._id} data={e} />) :    
          (<tr><td className="center" colSpan={3}><p>No Data</p></td></tr>)
          }
        </tbody>
      </table>  
      <div className={classNames("table_footer", {"mb-70": pageCount < 2})}>
        <div className="regist_button" onClick={movePage}><p>게시물 등록</p><i className="board_regist_icon"></i></div>
      </div>
      { pageCount > 1 && <ReactPaginate
          breakLabel="..."
          nextLabel={null}
          className="board_pagination"
          onPageChange={(e:any)=>handlePageClick(e)}
          forcePage={currentPage}
          pageCount={pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          previousLabel={null}
        />}
      </div>
    </div>
  )
}

export default Main;