import './modal.scss';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { connectModalSetting, RootState } from '../../../store';

interface AlertModalProps {
  description?: JSX.Element;
  closeButton?: string;
  confirmButton?: string;
  confirmClassName?: string;
  confirmFunc?: Function;
  data?: any;

}

const AlertModal = ({description, closeButton, confirmButton, confirmClassName, data, confirmFunc}:AlertModalProps) => {

  const dispatch = useDispatch();
  // modal open 여부
  const modalOpen = useSelector((state: RootState) => state.modal.open);
  // modal 열고닫기 기능
  const onModalSetting = (diff: boolean) => {
    dispatch(connectModalSetting(diff));
  };

  return (
    <div className="dim" onClick={() => {
      onModalSetting(!modalOpen);
    }}>
      <div className="modal alert" onClick={e => e.stopPropagation()}>
        <div className="close_button" onClick={() => {
                onModalSetting(!modalOpen);
              }}></div>
        <div className="modal_inner">
          {description}
        </div>
        <div className="button">
          { closeButton && 
            <div 
              className="modal_close_button"
              onClick={() => {
                onModalSetting(!modalOpen);
              }}
            >
              <p>{closeButton}</p>
            </div>
          }
          {confirmButton && 
            <div 
              className={classNames(`modal_confirm_button`, confirmClassName)}
              onClick={() => {
                confirmFunc && confirmFunc();
                onModalSetting(!modalOpen);
              }}
            >
              <p>{confirmButton}</p>
            </div>
          } 
        </div>
      </div>
    </div>
  )
}

export default AlertModal;