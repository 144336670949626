import { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './assets/css/reset.css';
import './assets/css/app.scss';
import { MenuItemProps } from './shared/types/menu.types';
import { Main, NotFound, BoardDetail, Login, BoardWrite } from './pages';
import { MainLayout, LoginLayout } from './components';

const App = () => {

  const { t } = useTranslation();

  const location = useLocation();

  // Header Menu
  const menu:MenuItemProps[] = [
    {id: 1, category: 'main', title: 'Board Manager', link: '/', element: <Main />},
    {id: 2, category: 'main', title: 'Detail', link: '/detail/:id', element: <BoardDetail />},
    {id: 3, category: 'main', title: 'Write', link: '/write', element: <BoardWrite />},
    {id: 80, category: 'login', title: 'Login', link: '/login', element: <Login />},
    {id: 99, category: 'error', title: 'NotFound', link: '*', element: <NotFound />},
  ]

  const mainRouter = menu.filter((m) => m.category === 'main');
  const loginRouter = menu.filter((m) => m.category === 'login');
  const errorRouter = menu.filter((m) => m.category === 'error');

  return (
    <Routes>
      {/* main Loyout을 사용하는 라우터 */}
      <Route element={<MainLayout menu={menu} location={ location.pathname } />}>
        { mainRouter.map(( res ) => <Route key={res.id} path={res.link} element={res.element} />)}
      </Route>

      {/* login Loyout을 사용하는 라우터 */}
      <Route element={<LoginLayout />}>
        { loginRouter.map(( res ) => <Route key={res.id} path={res.link} element={res.element} />)}
      </Route>

      {/* layout을 사용하지 않는 라우터 */}
      { errorRouter.map(( res ) => <Route key={res.id} path={res.link} element={res.element} />)}
    </Routes>
  );
}

export default App;
