import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { BoardItemProps, BoardProps } from '../../shared/types/board.types';
import { dateToString } from './../../shared/utils/date/date';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Data {
  data: BoardItemProps;
}

const BoardItem = ({ data } : Data) => {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();

  // const date = new Date(data.regdate);
  /**
   * detail 페이지로 이동
   * 
   * @author jslee
   * @since 2022-12-14
   */
  const moveDetail = () => {
    navigate(`/detail/${data._id}`);
  }

  return (
    <tr onClick={moveDetail}>
      <td><p className={classNames(`icon`, data.category && data.category.toLowerCase())}>{data.category}</p></td>
      <td className="board_item_title"><p>{data.title}</p></td>
      <td className="board_item_date"><p>{dateToString(data.regdate, 'yyyy.MM.dd (E)', i18n.language)}</p></td>
    </tr>
  )
}
export default BoardItem;