import React, { useRef, useEffect, useState } from "react";
import SunEditor from 'suneditor-react';
import SunEditorCore from "suneditor/src/lib/core";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
// import { font, image } from 'suneditor/src/plugins'
import './sunEditor.scss'
import classNames from "classnames";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/login/useAuth";
import { useNavigate } from "react-router-dom";
import { SERVER_HOST } from "../../shared/utils";
import { BoardItemProps, BoardProps } from "../../shared/types/board.types";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Editor = ({ title, category, data }: { title: string, category: string, data?: BoardItemProps }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(data?.content ? data.content : '');
  const [file, setFile] = useState('');
  const [attachment, setAttachment] = useState('');
  // const [thumbnailFile, setThumbnailFile] = useState<any>();
  const [previewURL, setPreviewURL] = useState<string | ArrayBuffer | null>('');
  const [attachmentPreviewURL, setAttachmentPreviewURL] = useState<string | ArrayBuffer | null>('');
  const [preview, setPreview] = useState<any>(null);
  const [attachmentPreview, setAttachmentPreview] = useState<any>(null);
  const [previewCheck, setPreviewCheck] = useState<any>({
    isCheck: false,
    value: "이미지 사이즈는 최소 가로400px 세로300px로 JPG, PNG, GIF 최대 2MB 크기의 이미지만 등록 가능합니다."
  });
  const [attachmentPreviewCheck, setAttachmentPreviewCheck] = useState<any>({
    isCheck: false,
    value: "첨부 파일을 등록해 주세요."
  });
  const fileRef = useRef<any>(null);
  const attachmentRef = useRef<any>(null);
  const editor = useRef<SunEditorCore>();
  const { t, i18n } = useTranslation();
  const { user, logout } = useAuth();

  const mode: number = useSelector((state: RootState) => state.write.mode);

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
    // console.log("sunEditor", sunEditor);
  };

  useEffect(() => {
    console.log(" mode?", mode);
  }, [mode])

  // useEffect(()=> {
  //   console.log("입력된 값? ", value);
  // },[value])

  useEffect(() => {
    if (file !== '') //처음 파일 등록하지 않았을 때를 방지
      setPreview(<img className='img_preview' src={previewURL as string}></img>);
    if (attachment !== '')
      setAttachmentPreview(<p>{attachmentPreviewCheck.value}</p>);
    return () => {
    }
  }, [previewURL, attachmentPreviewURL]);

  /**
   * File을 감지하고 파일을 업로드 하는 부분
   * 
   * @param event input으로 업로드 받은 파일
   * 
   * @author jslee
   * @since 2022-12-13
   */
  const handleFileOnChange = (event) => {//파일 불러오기
    event.preventDefault();
    let file = event.target.files[0];
    // console.log("test file", file);
    // console.log("file size origin>>", file.size);
    let fileSize = file.size / 1024 / 1024;
    // console.log("file size trans >>", fileSize);
    if (fileSize > 2) {
      setPreviewCheck({
        isCheck: true,
        value: "이미지 용량이 2MB를 초과 하였습니다."
      })
    } else {
      // setThumbnailFile(file);
      let reader = new FileReader();
      reader.onloadend = () => {
        setFile(file);
        setPreviewURL(reader.result);
      }
      if (file) {
        reader.readAsDataURL(file);
        setPreviewCheck({
          isCheck: false,
          value: "이미지 사이즈는 최소 가로400px 세로300px로 JPG, PNG, GIF 최대 2MB 크기의 이미지만 등록 가능합니다."
        })
      }
    }
  }
  const handleAttachmentOnChange = (event) => {//파일 불러오기
    event.preventDefault();
    let file = event.target.files[0];
    // console.log("test file", file);
    // console.log("file size origin>>", file.size);
    let reader = new FileReader();
    reader.onloadend = () => {
      setAttachment(file);
      setAttachmentPreviewURL(reader.result);
    }
    if (file) {
      console.log('file >>>>>>',file);
      reader.readAsDataURL(file);
      setAttachmentPreviewCheck({
        isCheck: false,
        value: file.name
      });
    }
  }

  /**
   * 삭제를 담당하는 function
   * 썸네일로 등록된 이미지를 삭제하고, input의 기록을 초기화 한다.
   * 
   * @author jslee
   * @since 2022-12-13
   * 
   */
  const deleteFile = () => {
    setFile('');
    setPreview(null);
    fileRef.current.value = "";
  }
  const deleteAttachment = () => {
    setAttachment('');
    setAttachmentPreview(null);
    setAttachmentPreviewCheck({
      isCheck: false,
      value: "첨부 파일을 등록해 주세요."
    })
    attachmentRef.current.value = "";
  }

  const registBoardWrite = async () => {
    // console.log("[write] file", file);
    // console.log("[write] file", thumbnailFile);
    let adminForm = new FormData();

    adminForm.append('nickname', 'tester01');
    adminForm.append('lang', i18n.language);
    adminForm.append('category', category);
    adminForm.append('title', title);
    adminForm.append('content', value);
    // adminForm.append('file', thumbnailFile);

    if (file) adminForm.append('file', file);
    if (attachment) adminForm.append('attachment', attachment);

    console.log('adminForm',adminForm);

    if (mode === 1) {
      await axios.post(SERVER_HOST + `/admin/post`, adminForm, {
        headers: {
          "authorization": user.token,
          "Content-Type": "multipart/form-data"
        }
      })
        .then((res) => {
          console.log("res", res);
          if (res.data.error_code === 403) {
            alert("토큰이 유효하지 않습니다. 다시 로그인 해주세요");
            logout();
          }
          if (res.data.response === 200) {
            navigate(`/`);
          }
        })
        .catch(error => console.error(error));
    } else if (mode === 2) {
      
      adminForm.append("id", data?._id as string);
      await axios.put(SERVER_HOST + `/admin/post`, adminForm, {
        headers: {
          "authorization": user.token,
          "Content-Type": "multipart/form-data"
        }
      })
        .then((res) => {
          console.log("res", res);
          if (res.data.response === 200) {
            navigate(`/`);
          }
        })
        .catch(error => console.error(error));
    }
  }

  const handleImageUploadBefore = async (files: any, info: any, uploadHandler: any) => {
    try {
      // console.log("UploadBefore file", files[0]);
      const formData = new FormData();
      formData.append('file', files[0]);

      await axios.post(SERVER_HOST + "/admin/post/editorfile", formData, {
        headers: {
          "authorization": user.token,
          "Content-Type": "multipart/form-data"
        }
      })
        .then((res: any) => {
          // console.log("UploadBefore", res);
          uploadHandler({
            result: [
              {
                "url": SERVER_HOST + "/" + res.data.data,
                "name": files[0].name,
                "size": files[0].size as string
              }
            ]
          })
        })
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="text-editor">
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        width="100%"
        height="650px"
        // name="text-editor"
        // value
        defaultValue={value as string}
        setOptions={{
          buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', /** 'subscript', 'superscript' */],
            ['fontColor', 'hiliteColor'/** , 'textStyle' */],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', /**'lineHeight'*/],
            // '/', // Line break
            ['table', 'link', 'image', 'video',  /** 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
            /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
            [/**'fullScreen',*/ 'showBlocks', 'codeView'],
            ['removeFormat'],
            // ['preview', 'print'],
            // ['save', 'template'],
          ],
          defaultStyle: "font-family: 'Roboto'; font-size: 14px;",
          font: ['맑은고딕', 'Aria', '굴림', '바탕',],
          // plugins: [font, image]
          stickyToolbar: -1,
          maxWidth: "1128px",
          imageUploadSizeLimit: 10000000,
          videoHeight: '650px',
          videoRatioShow: false,
        }}
        setAllPlugins={true}
        onImageUploadBefore={(files, info, uploadHandler) => { handleImageUploadBefore(files, info, uploadHandler) }}
        onChange={(e) => { setValue(e) }}
      />

      <div className="thumbnail">
        <div className="thumbnail_left">
          <div className="thumbnail_title">썸네일</div>
          <div className="preview_wrapper">
            {preview}
            <p className={classNames({ 'error': previewCheck.isCheck })}>{previewCheck.value}</p>
          </div>
        </div>
        <div className="thumbnail_file_box">
          {preview && <button className="delete" onClick={deleteFile}>삭제</button>}
          <label htmlFor="thumbnail_file" className="thumbnail_file">파일선택</label>
          <input ref={fileRef as any} type="file" name="thumbnail_file" id="thumbnail_file" onChange={handleFileOnChange} accept="image/x-png,image/gif,image/jpeg" />
        </div>
      </div>

      <div className="attachment">
        <div className="attachment_left">
          <div className="attachment_title">첨부파일</div>
          <div className="preview_wrapper">
            {/* {attachmentPreview} */}
            <p className={classNames({ 'error': attachmentPreviewCheck.isCheck })}>{attachmentPreviewCheck.value}</p>
          </div>
        </div>
        <div className="attachment_file_box">
          {attachment && <button className="delete" onClick={deleteAttachment}>삭제</button>}
          <label htmlFor="attachment_file" className="attachment_file">파일선택</label>
          <input ref={attachmentRef as any} type="file" name="attachment_file" id="attachment_file" onChange={handleAttachmentOnChange} />
        </div>
      </div>

      <div className="button_wrapper">
        <div className="cancel" onClick={() => navigate(-1)}>취소</div>
        <div className="regist" onClick={registBoardWrite}>등록</div>
      </div>
    </div>
  );
}
export default Editor;