import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import classNames from "classnames";
import './boardDetail.scss'
import { AlertModal } from './../../components';
import { useSelector } from 'react-redux';
import { connectModalSetting, RootState, writeModeSetting, writeUpdateData } from '../../store';

import { BoardItemProps, BoardProps } from '../../shared/types/board.types';
import { useState } from 'react';
import { dateToString, getData, SERVER_HOST } from '../../shared/utils';
import axios from 'axios';
import { useAuth } from '../../hooks/login/useAuth';
import { useTranslation } from 'react-i18next';

export const getLastPath = (url: string) => {
  var splitUrl: string[] = [];
  splitUrl = url.split('/');
  return splitUrl[1];
}

const BoardDetail = () => {
  const params = useParams();
  const [data, setData] = useState<BoardItemProps>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const modalOpen = useSelector((state: RootState) => state.modal.open);

  useEffect(() => {
    getData({
      id: params.id
    }).then((res) => {
      console.log(res[0]);
      setData(res[0]);
    })
      .catch(error => console.error(error));

  }, []);

  useEffect(() => {
    // console.log("url query params", params);
    // console.log("modalOpen status", modalOpen);
  }, [modalOpen])

  const deleteFunc = (data: BoardItemProps) => {
    // console.log("[deleteFunc] data", data);
    axios.delete(SERVER_HOST + `/admin/post`, {
      data: {
        id: data._id
      },
      headers: {
        authorization: user.token
      }
    })
      .then(res => {
        console.log("delete 결과", res);
        navigate(`/`);
      })
      .catch((error) => {
        console.error(error);
      })
  }

  return (
    <div className="container board_detail">
      <div className="board_detail_header">
        <p className={classNames(`icon`, data && data.category.toLowerCase())}>{data && data.category}</p>
        <div className="board_detail_title"><p>{data && data.title}</p></div>
        <div className="board_detail_date"><p>{data && dateToString(data?.regdate, 'yyyy-MM-dd (E)', i18n.language)}</p></div>
        {data?.attachment && (
          <div className="board_detail_attachment">
            <p className="title">첨부파일</p>
            <div className="box">
              <div
                className="button_attachment"
                onClick={() => {
                  // dispatch(connectModalSetting(!modalOpen));
                  // window.location.href = `${SERVER_HOST}/${data.attachment}`;
                  window.open(`${SERVER_HOST}/${data.attachment}`, '_blank', 'download');
                }}
              >
                <p>
                  {getLastPath(data.attachment)}
                </p>
              </div>
            </div>
          </div>
        )}

      </div>
      <div className="board_detail_body">
        <div className="board_detail_content">
          <div className="board_detail_text" dangerouslySetInnerHTML={{ __html: data?.content as string }}>
          </div>
        </div>
        <div className="board_detail_func">
          <div className="board_detail_left" onClick={() => {
            navigate(-1)
          }}>
            <p>목록보기</p><i className="icon"></i>
          </div>
          <div className="board_detail_right">
            <div className="update" onClick={async () => {
              dispatch(writeModeSetting(2));
              dispatch(writeUpdateData(data));
              await navigate(`/write`);
            }}><p>수정</p></div>
            <div className="delete" onClick={() => { dispatch(connectModalSetting(!modalOpen)) }}><p>삭제</p></div>
          </div>
        </div>
      </div>
      {modalOpen && <AlertModal
        description={
          <div className="content">
            <p className="title">게시글을 삭제 하시겠습니까?</p>
            <p className="description">삭제된 게시물은 복구할 수 없습니다.</p>
          </div>}
        closeButton="닫기"
        confirmButton="삭제"
        confirmClassName="delete_button"
        // data={data}
        confirmFunc={() => deleteFunc(data as BoardItemProps)}
      />}
    </div>
  );
}
export default BoardDetail;