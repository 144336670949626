import { Link, Navigate, useOutlet } from "react-router-dom";
import { MenuProps } from './../../shared/types/menu.types';
import { Header, Footer } from '../index';
import { useAuth } from "../../hooks/login/useAuth";
const MainLayout = ({menu, location}: MenuProps) => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
  // if (false) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="app">
      <Header menu = { menu } location = { location } />
      <div className="content">
        {outlet}
      </div>
      <Footer />
    </div>
  )
}
export default MainLayout;