import axios from 'axios';
import { SERVER_HOST } from '../config';

export const getData = async(params) => {
  let data;
  await axios.get(SERVER_HOST+`/admin/post`, {
    params: params
  })
  .then(res => {
    data = res.data.data;
  })
  .catch(error => {
    console.error(error);
  })

  
  if(data) {
    return data;
  } else {
    return [];
  }
};