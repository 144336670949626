import classNames from 'classnames';
import { MenuProps } from './../../shared/types/menu.types';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { languages } from '../../assets/locales/i18n';
import { useAuth } from '../../hooks/login/useAuth';

import './headerStyle.scss';

const Header = ({ menu, location }: MenuProps) => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**
   * 언어를 변환하는 부분
   * 
   * @param locale 현재 선택한 언어
   * 
   * @author colen
   * @since 2022-12-08
   */
  const toggleLocales = (locale: string) => {
    // console.log("locale", locale);
    i18n.changeLanguage(locale);
    sessionStorage.setItem('language', locale);
  }

  const { user, logout } = useAuth();

  const logoutHandler = () => {
    logout();
  }

  return (
    <header>
      <Link to='/'>
        <div className='left_box'>
          <i className='logo'></i>
        </div>
      </Link>
      <div className='right_box'>
        {
        languages.map((e:any) => <div className={classNames(`lang`, e === i18n.language ? "on" : "")} key={e} onClick={(index) => toggleLocales(e)}>{t(e)}</div>)
        }
        {user ? <div className="login" onClick={logoutHandler}><p>로그아웃</p></div> : <div className="login" onClick={() => navigate(`/login`)}><p>로그인</p></div>}
      </div>
    </header>
  )
}
export default Header;