import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../../hooks/login/useAuth";
import { Footer } from '../index';

const LoginLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if(user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="app">
      <div className="content">
        {outlet}
      </div>
      <Footer />
    </div>
  )
}
export default LoginLayout