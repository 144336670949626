import './footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <p>Copyright© 2022 NEXTOR All rights reserved.</p>
    </footer>
  )
}

export default Footer;