import './boardWrite.scss';
import { Editor } from './../../components';
import { useState, useEffect } from 'react';
import { BoardItemProps, BoardProps } from '../../shared/types/board.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useTranslation } from 'react-i18next';
// import { dateToString } from '../../shared/utils';

const BoardWrite = () => {
  
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('News');
  const {t, i18n} = useTranslation();

  const mode:number = useSelector((state:RootState) => state.write.mode);
  const data:BoardItemProps = useSelector((state:RootState) => state.write.data);
  const languageText = () => {
    if(i18n.language === 'ko') {
      return 'KR';
    } else if(i18n.language === 'en'){
      return 'EN';
    }
  }
  useEffect(()=> {
    // let testDate = new Date('2002-01-20T03:27:31.959Z');
    // console.log(dateToString('2002-01-14T03:27:31.959Z', 'yyyy.MM.dd E요일'));
    if(mode === 2 ){
      setTitle(data.title);
      setCategory(data.category);
    }
  },[])
  return (
    <div className="container board_write">
      <div className="content_frame">
        <div className="board_title">
          <p>게시판 관리 ({languageText()})</p>
        </div>

        <div className="top_content">
          <select name="write_category" id="write_category" className='write_category' value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="News">News</option>
            <option value="Notice">Notice</option>
          </select>
          <input className="write_title" type="text" placeholder="제목 (최대 50자)" value={title} onChange={(e) => setTitle(e.target.value)} maxLength={50} />
        </div>
        <Editor 
          category={category} 
          title={title}
          data={data}
        />
      </div>
    </div>
  )
}
export default BoardWrite;